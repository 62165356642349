import React from "react";
import { StandardText } from "..";
import { Col, Container } from "./PlayerData.style";

export interface PlayerDataProps {
  className: string;
  illustrationUrl: string;
  title: string;
  description: string;
}

const PlayerData: React.FC<PlayerDataProps> = props => {
  return (
    <Container id={props.title.toLowerCase()} className={`player-data-container type-${props.className}`}>
      <Col className={"illustration-col"}>
        <img style={{ width: "100%" }} src={props.illustrationUrl} alt="" />
      </Col>
      <Col className={"text-col"}>
        <StandardText className={"hud-label"} text={props.title} />
        <StandardText className={"hud-value"} text={props.description} />
      </Col>
    </Container>
  );
};

export default PlayerData;
