import "mobx-react-lite/batchingForReactDom";
import React, { Suspense, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import { Spinner } from "./DumbComponents";
import { HOME, LANDING, ADMIN_SUFFIX } from "./routing";
import LogicContext from "./context/context";
import applicationCore from "./Ducks/application/core";
import applicationState from "./Ducks/application/state";
import authCore from "./Ducks/Auth/core";
import authState from "./Ducks/Auth/state";
import gameCore from "./Ducks/game/core";
import gameState from "./Ducks/game/state";
import userCore from "./Ducks/User/core";
import userState from "./Ducks/User/state";
import { GlobalContainer } from "./containers/public/publicContainer.style";
import { preFlightCheck } from "utils";
import { CircularProgress } from "@material-ui/core";

const publicContainer = React.lazy(() => import("./containers/public"));
const homeContainer = React.lazy(() => import("./containers/game"));
const adminContainer = React.lazy(() => import("./containers/admin"));

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAppFunctionnal, setIsAppFunctionnal] = useState(true);

  const setApp = async () => {
    setIsLoading(true);

    try {
      (await applicationCore.setApplicationConfig()) &&
        (await applicationCore.setAssetsPartsList()) &&
        (await applicationCore.setAvailableAvastarList());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsAppFunctionnal(preFlightCheck());
    setApp();
  }, []);

  if (!isAppFunctionnal) {
    return <p>Missing env variables. Please check your console and build config.</p>;
  }

  return isLoading ? (
    <GlobalContainer style={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <CircularProgress />
    </GlobalContainer>
  ) : applicationState.store.config !== undefined && authState.store.authService !== undefined ? (
    <I18nextProvider i18n={i18next}>
      <LogicContext.Provider
        value={{ applicationCore, applicationState, authCore, gameCore, gameState, userCore, userState, authState }}
      >
        <div>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route path={HOME.path} component={homeContainer} />
              <Route path={ADMIN_SUFFIX} component={adminContainer} />
              <Route path={LANDING.path} component={publicContainer} />
              <Route path={"/"} exact>
                <Redirect to={LANDING.path} />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </LogicContext.Provider>
    </I18nextProvider>
  ) : null;
};

export default App;
