import { z } from "zod";

export type TranslatedContent = {
  lang: string;
  value: string;
};

export type ApplicationMedia = {
  type: "image" | "video";
  url: TranslatedContent[];
  poster?: TranslatedContent[];
};
interface FooterItem {
  htmlSelector: string;
  buttonName: TranslatedContent[];
  value: TranslatedContent[];
  type: "link" | "modal";
}

interface FooterLink extends FooterItem {
  type: "link";
}

export type UserMetadata = {
  name: string;
  type: "text" | "list" | "checkbox" | "password" | "email";
  placeholder?: TranslatedContent[];
  possibleValueList?: string[];
  defaultValue?: string | boolean;
  icon?: string;
  isIdentifier?: boolean;
  isRequired?: boolean;
  regex?: string;
  maxLength?: number;
  isDisplayed?: boolean;
};

export type QuizvivorCongitoConfig = {
  externalProviders?: string[];
  region: "eu-west-1" | "eu-west-3";
  userPoolId: string;
  userPoolWebClientId: string;
  domain: string;
};

export type ApplicationConfig = {
  applicationName: string;
  userToken?: string;
  _id: string;
  branding: {
    primaryColor: string;
    secondaryColor: string;
    logo: string;
    logoLink?: string;
    trailer: ApplicationMedia;
    gameRules?: FooterLink;
    legalPrivacyPolicy?: FooterLink;
    startDate?: Date;
    stopDate?: Date;
  };
  footerLinkList?: FooterItem[];
  userMetadataScheme: UserMetadata[];
  jackpotAmount: number;
  defaultLanguage?: string;
  languageList: string[];
  fallbackLng?: string;
  cognitoConfig: QuizvivorCongitoConfig;
};

export const StatisticsSchema = z
  .object({
    userMetadata: z.record(z.string(), z.any()),
    firstGame: z.coerce.date().optional(),
    lastGame: z.coerce.date().optional(),
    numberOfGames: z.number().default(0),
    totalScore: z.number().default(0),
    ranking: z.object({
      position: z.number(),
      numberOfPlayers: z.number(),
    }),
  })
  .array();

export type Statistics = z.infer<typeof StatisticsSchema>;
