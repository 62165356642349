import axios from "axios";
import { ZodSchema } from "zod";
import { API_URL, APPLICATION_ID } from "../consts";
import AuthState from "../Ducks/Auth/state";
interface CommonHeaderObject {
  Authorization: string;
  "Content-Language": string;
  ["application-id"]: string;
}

const get = async <T = any>(
  { path, auth, language }: { auth?: boolean; path: string; language?: string },
  ValidationSchema?: ZodSchema,
) => {
  const headers: CommonHeaderObject = {
    Authorization: "",
    "application-id": APPLICATION_ID,
    "Content-Language": `${language}`,
  };

  if (auth && AuthState.store.authService) {
    const token = await AuthState.store.authService.getIdToken();
    if (!token) throw new Error("No token for authentified request");
    headers["Authorization"] = `Bearer ${token?.getJwtToken()}`;
  }

  const { data } = await axios.get<T>(`${API_URL}${path}`, { params: { language }, headers: { ...headers } });

  if (ValidationSchema) {
    try {
      const parsedData = ValidationSchema.parse(data);

      return parsedData as T;
    } catch (err) {
      console.error(err);

      throw new Error("Invalid data from API");
    }
  }

  return data;
};

const post = async <T = any>({
  auth,
  path,
  body,
  language,
}: {
  auth?: boolean;
  path: string;
  body: any;
  language?: string;
}) => {
  const headers: CommonHeaderObject = {
    Authorization: "",
    "application-id": APPLICATION_ID,
    "Content-Language": `${language}`,
  };
  if (auth && AuthState.store.authService) {
    const token = await AuthState.store.authService.getIdToken();
    if (!token) throw new Error("No token for authentified request");
    headers["Authorization"] = `Bearer ${token?.getJwtToken()}`;
  }

  return axios.post<T>(`${API_URL}${path}`, body, { params: { language }, headers: { ...headers } });
};

export default {
  get,
  post,
};
