import { err, ok } from "neverthrow";
import { UserServicesInterface } from "../services/User.service";
import { UserStateInterface } from "../state/index";
import { StorageServiceInterface } from "../../../services/storage.service";
import { UserType } from "../models/User.model";
import { Avatar } from "../../Avatar/models/avatar.model";
import { ApplicationStateInterface } from "../../application/state";
//import AuthService from "config/cognito/service";
import { User } from "@upandgo/auth-package";
import { AuthStateInterface } from "Ducks/Auth/state";
import { qvAvatar2UAGAvatar } from "utils";

interface UserCoreInterface {
  userService: UserServicesInterface;
  userState: UserStateInterface;
  storageService: StorageServiceInterface;
  applicationState: ApplicationStateInterface;
  authState: AuthStateInterface;
}

const Core = ({ userService, userState, storageService, applicationState, authState }: UserCoreInterface) => {
  const setMe = async () => {
    try {
      const idToken = await authState.store.authService?.getIdToken();

      if (!idToken) return;
      const me = await userService.getMe();
      const user = new User({
        avatar: qvAvatar2UAGAvatar(me.avatar ?? ""),
        email: me.metadata.email,
        firstName: me.metadata.firstName,
        lastName: me.metadata.lastName,
        pseudo: me.metadata.pseudo,
        cognitoId: "",
      });

      userState.mutator.setMe(me, user.computeDisplayName);
      return ok(user);
    } catch (e) {
      return err(`${e.message}`);
    }
  };

  const isGameClose = () => {
    if (applicationState.store.config?.branding.startDate) {
      return new Date(Date.now()) < applicationState.store.config?.branding.startDate;
    }
    if (applicationState.store.config?.branding.stopDate) {
      return new Date(Date.now()) > applicationState.store.config?.branding.stopDate;
    }
    return false;
  };

  const canAccessContainer = (type: UserType) => {
    const token = storageService.getToken();
    if (isGameClose()) return false;
    if (token === null) return false;
    if (!storageService.isTokenValid(token)) return false;
    if (token) {
      if (type === "unauthorized") return false;
      return true;
    } else {
      return false;
    }
  };

  const setTopUserList = async () => {
    try {
      const ranking = await userService.getTopUserList();
      userState.mutator.setTopUserList(ranking);
      return ok(ranking);
    } catch (e) {
      console.error(e);
      return err(e);
    }
  };

  const updateAvatar = async (avatar: Avatar) => {
    try {
      if (!userState.store.me) throw "No user";
      const modifiedAvatar = await userService.updateAvatar(avatar);
      userState.mutator.setMe({ ...userState.store.me, avatar: modifiedAvatar }, userState.store.displayName);
      return ok(modifiedAvatar);
    } catch (e) {
      return err(e);
    }
  };

  const clearMe = () => {
    userState.mutator.setMe(undefined, "");
  };

  return {
    setMe,
    clearMe,
    canAccessContainer,
    setTopUserList,
    updateAvatar,
  };
};

export default Core;
