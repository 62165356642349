import errors from "./errors/errors.json";
import landing from "./landing/landing.json";
import joker from "./joker/joker.json";
import fifthy from "./fifthy/fifthy.json";
import questions from "./questions/questions.json";
import home from "./home/home.json";
import loose from "./loose/loose.json";
import tutorial from "./tutorial/tutorial.json";
import win from "./win/win.json";
import rules from "./rules/rules";
import legal from "./legal/legal.json";
import avatarEditor from "./avatarEditor/avatarEditor.json";
import rankingList from "./rankingList/rankingList.json";
import hud from "./hud/hud.json";
import final from "./final/final.json";
import round from "./round/round.json";
import common from "./common.json";
import endOfGame from "./endOfGame/endOfGame.json";
import signUp from "./signUp.json";
import login from "./login/login.json";
import stat from "./stat/stat.json";

export default {
  common,
  errors,
  round,
  landing,
  joker,
  fifthy,
  final,
  questions,
  home,
  loose,
  tutorial,
  win,
  rules,
  legal,
  avatarEditor,
  rankingList,
  hud,
  endOfGame,
  signUp,
  login,
  stat,
};
