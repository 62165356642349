import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import CircledButton from "../CircledButton/CircledButton";
import LogicContext from "../../context/context";
import mute from "assets/mute.svg";
import unmute from "assets/unmute.svg";

interface Props {
  muteOrPlay: (mute: boolean) => void;
}

const MuteOrPlayButton: React.FC<Props> = props => {
  const { applicationState } = useContext(LogicContext);

  const muteOrPlay = () => {
    applicationState.store.isMuted ? applicationState.mutator.unmute() : applicationState.mutator.mute();
  };

  useEffect(() => {
    props.muteOrPlay(applicationState.store.isMuted);
  }, [applicationState.store.isMuted]);

  return <CircledButton onClick={muteOrPlay} iconUrl={applicationState.store.isMuted ? mute : unmute} />;
};

export default observer(MuteOrPlayButton);
