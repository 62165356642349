import { Avatar } from "./Ducks/Avatar/models/avatar.model";

export enum AppStage {
  LOCAL = "local",
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}

export type StyleSheet = { [key: string]: React.CSSProperties };

export type Media = {
  type: "image" | "video";
  url: string;
};

export type UserType = "authorized" | "unauthorized";

type genericKeyString = {
  [key: string]: string;
};

export type SignupInput = genericKeyString & {
  avatar: Avatar;
};
