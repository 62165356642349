import Store from "./auth.store";
import Mutator from "./auth.mutator";

const store = new Store();
const mutator = new Mutator({ authStore: store });

export interface AuthStateInterface {
  store: Store;
  mutator: Mutator;
}

export default {
  store,
  mutator,
};
