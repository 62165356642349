import React from "react";
import { MainContainer } from "./ModalContent.style";
import { GameRulesSlider, ModalHTMLText } from "../../DumbComponents";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

interface Props {
  modalType?: string;
  secondaryColor: string;
  htmlText?: string;
}

const ModalContent: React.FC<Props> = props => {
  const { t } = useTranslation(["rules", "legal"]);

  return (
    <>
      <MainContainer>
        {props.modalType === "gameRules" && (
          <>
            <GameRulesSlider
              secondaryColor={props.secondaryColor}
              title={t("rules:rulesTitle", { returnObjects: true })}
              text={t("rules:rulesRichText", { returnObjects: true })}
              image={t("rules:rulesImagesArray", { returnObjects: true })}
            />
          </>
        )}
        {props.modalType === "freeHTML" && (
          <>
            <ModalHTMLText secondaryColor={props.secondaryColor} htmlText={props.htmlText ?? ""} />
          </>
        )}
      </MainContainer>
    </>
  );
};

export default observer(ModalContent);
