import React from "react";
import { CircularProgress } from "@material-ui/core";
import { TextButton, TextButtonContainer } from "./StyledButton.style";

interface Props {
  value?: string;
  onClick?: (event: React.MouseEvent) => void;
  onSubmit?: () => void;
  onMouseOver?: (event: React.MouseEvent) => void;
  onMouseOut?: (event: React.MouseEvent) => void;
  confirmAction?: () => void;
  customClass?: string;
  loading?: boolean;
}

const StyledButton: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = props => {
  const { onMouseOut, onMouseOver, onClick, onSubmit, value, children, loading, ...remainingProps } = props;
  return (
    <TextButtonContainer>
      <TextButton
        className={`styled-button ${props.customClass || ""}`}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        onClick={onClick}
        onSubmit={onSubmit}
        {...remainingProps}
      >
        {loading ? <CircularProgress /> : value || children}
      </TextButton>
    </TextButtonContainer>
  );
};

export default StyledButton;
