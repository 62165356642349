import styled from "styled-components";
import fiftyFifty from "assets/game/fifty-fifty.svg";

export const FifthyFifthyDiv = styled.button`
  background: url(${fiftyFifty});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 40%;
  z-index: 500000;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 0;
  outline: none;
  border: none;
`;
