import Core from "./application.core";
import ApplicationState from "../state";
import applicationService from "../services/application.service";
import AuthState from "Ducks/Auth/state";

const applicationCore = Core({
  applicationState: ApplicationState,
  applicationService,
  authState: AuthState,
});

export default applicationCore;
