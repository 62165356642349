import { useState, useLayoutEffect } from "react";
import React from "react";
import { UserMetadata } from "./Ducks/application/models/application.model";
import i18next from "i18next";
import { API_URL, APPLICATION_ID, APP_STAGE } from "consts";
import { AppStage } from "types";
import { Avatar as QVAvatar } from "Ducks/Avatar/models/avatar.model";
import { Avatar } from "@upandgo/auth-package";

export const preFlightCheck = () => {
  const appStageKeys = Object.values(AppStage);
  let isAppReady = true;
  try {
    if (!API_URL || API_URL.length === 0) throw new Error("API_URL not set");
    if (!APPLICATION_ID || APPLICATION_ID.length === 0) throw new Error("APPLICATION_ID not set");
    if (!APP_STAGE || !appStageKeys.includes(APP_STAGE))
      throw new Error(`APP_STAGE not set. Should be one of ${appStageKeys}`);
  } catch (err) {
    isAppReady = false;
    console.error(err);
  }

  return isAppReady;
};

export const computeAvatarUrlFromIndexes = (
  endpoint: string,
  partLetter: string,
  partIndex: number,
  colorIndex?: number,
) => {
  return `${endpoint}${partLetter}/${partLetter}${partIndex + 1}${
    colorIndex !== undefined ? `-${colorIndex + 1}` : ""
  }.png`;
};

export const getKeys = <T extends {}>(o: T): Array<keyof T> => Object.keys(o) as Array<keyof T>;

export const capitalize = (string: string): string => {
  const lowercase = string.toLowerCase();

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const useWindowPixelRatio = () => {
  const [pixelRatio, setPixelRatio] = useState<{ pixelRatio: number }>({
    pixelRatio: window.devicePixelRatio,
  });

  const handleZoom = () => {
    setPixelRatio({
      pixelRatio: window.devicePixelRatio,
    });
  };

  useLayoutEffect(() => {
    handleZoom();
    window.addEventListener("resize", handleZoom);
    return () => window.removeEventListener("resize", handleZoom);
  }, []);

  return pixelRatio;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  function updateSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return windowSize;
};

export const spanify = (stringBlock: string, customItemClassname?: string) => {
  const stringArray = stringBlock.split("///");
  return stringArray.map((item, index) => (
    <span key={index} className={`${customItemClassname}-${index}`}>
      {item}
    </span>
  ));
};

export const checkField = (userMetadataSchema: UserMetadata, metadataValue?: string) => {
  const errors: string[] = [];
  const PASSWORD_MIN_LENGTH = 8;
  const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!userMetadataSchema || userMetadataSchema.type === "checkbox") return { isValid: true, errors };

  if ((userMetadataSchema.isRequired || userMetadataSchema.isIdentifier) && !metadataValue) {
    errors.push(i18next.t("signUp:formValidation.required"));
  }

  if (metadataValue) {
    if (userMetadataSchema.type === "password" && metadataValue.length < PASSWORD_MIN_LENGTH) {
      errors.push(i18next.t("signUp:formValidation.invalidPasswordLength", { length: PASSWORD_MIN_LENGTH }));
    }
    if (userMetadataSchema.type === "email" && !emailRegexp.test(metadataValue)) {
      errors.push(i18next.t("signUp:formValidation.wrongMailFormat"));
    }
    if (userMetadataSchema.maxLength !== undefined && metadataValue.length > userMetadataSchema.maxLength) {
      errors.push(i18next.t("signUp:formValidation.maxLength", { maxLength: userMetadataSchema.maxLength }));
    }
    if (userMetadataSchema.regex && !new RegExp(userMetadataSchema.regex).test(metadataValue)) {
      errors.push(i18next.t("signUp:formValidation.invalidPattern"));
    }
  }

  return { isValid: !errors.length, errors };
};

export const qvAvatar2UAGAvatar = (avatar: QVAvatar): Avatar => {
  if (typeof avatar === "string") return avatar;

  return {
    s: avatar.shapeIndex,
    b: avatar.bodyIndex,
    c: avatar.pencilIndex,
    e: avatar.eyesIndex,
    h: avatar.hairIndex,
    m: avatar.mouthIndex,
    n: avatar.noseIndex,
    o: avatar.earsIndex,
  };
};

export const generateCsv = (data: { [key: string]: string }[]): string => {
  const csvHeaderRow = Object.keys(data[0]).join(";");
  const csvDataRows = data.map(item => Object.values(item).join(";"));
  const fileContent = [csvHeaderRow, ...csvDataRows].join("\n") + "\n";

  return fileContent;
};

export const downloadCsv = (rawCsvFileContent: string, outputFilename?: string) => {
  const hiddenElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(rawCsvFileContent)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = outputFilename || "result.csv";
  hiddenElement.click();
};
