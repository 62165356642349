import React from "react";
import { Image3D } from "../../styles/FX/3D/Rotation3dY.style";
import { FifthyDiv } from "./FifthyFifthy.style";
import fifty from "assets/fifty.png";

const FifthyFifthy: React.FC = () => {
  return (
    <FifthyDiv>
      <Image3D url={fifty} />
    </FifthyDiv>
  );
};

export default FifthyFifthy;
