import { Me } from "../Ducks/User/models/User.model";
import jwt from "jsonwebtoken";

export const STORAGE_KEYS = {
  USER_INFOS: "QV-userInfos",
  CURRENT_LANG: "QV-currentLang",
  TOKEN_KEY_NAME: "tokenTargetedKey",
};
export interface StorageServiceInterface {
  getTargetedKey: () => string | null;
  setTargetedKey: (prefix: string, userUniqueId: string) => void;
  clearTargetedKey: () => void;
  getToken: () => string | null;
  storeCurrentLanguage: (lang: string) => void;
  getCurrentLanguage: () => string | null;
  storeUserAuthInfos: (infos: Me) => void;
  getUserAuthInfos: () => Me | null;
  clearUserAuthInfos: () => void;
  isTokenValid: (token: string) => boolean;
  clearLocalData: () => void;
}

const setTargetedKey = (prefix: string, userUniqueId: string) => {
  const formattedKeyName = `${prefix}.${userUniqueId}.idToken`;
  return localStorage.setItem(STORAGE_KEYS.TOKEN_KEY_NAME, formattedKeyName);
};

const getTargetedKey = () => {
  return localStorage.getItem(STORAGE_KEYS.TOKEN_KEY_NAME);
};

const clearTargetedKey = () => {
  return localStorage.removeItem(STORAGE_KEYS.TOKEN_KEY_NAME);
};

export const getToken = () => {
  const currentKeyName = getTargetedKey();

  return currentKeyName && localStorage.getItem(currentKeyName);
};

const storeCurrentLanguage = (lang: string) => {
  localStorage.setItem(STORAGE_KEYS.CURRENT_LANG, lang);
};

const getCurrentLanguage = () => {
  return localStorage.getItem(STORAGE_KEYS.CURRENT_LANG);
};

const clearCurrentLanguage = () => {
  localStorage.removeItem(STORAGE_KEYS.CURRENT_LANG);
};

const storeUserAuthInfos = (infos: Me) => {
  localStorage.setItem(STORAGE_KEYS.USER_INFOS, JSON.stringify(infos));
};

const getUserAuthInfos = (): Me | null => {
  const infos = localStorage.getItem(STORAGE_KEYS.USER_INFOS);
  return infos ? JSON.parse(infos) : null;
};

const clearUserAuthInfos = () => {
  localStorage.removeItem(STORAGE_KEYS.USER_INFOS);
};

const clearLocalData = () => {
  clearUserAuthInfos();
  clearCurrentLanguage();
};

const isTokenValid = (token: string) => {
  if (token === null) return false;
  const payload: any = jwt.decode(token);
  if (payload) {
    if (Math.round(new Date().getTime() / 1000) > payload.exp) return false;
    return true;
  } else {
    return false;
  }
};

export default {
  setTargetedKey,
  getTargetedKey,
  clearTargetedKey,
  storeUserAuthInfos,
  getUserAuthInfos,
  clearUserAuthInfos,
  getToken,
  isTokenValid,
  storeCurrentLanguage,
  getCurrentLanguage,
  clearLocalData,
};
