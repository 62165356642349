import papagayo from "assets/papagayo/papagayo-high-res.png";
import papagayoJoker from "assets/papagayo/papagayo-speaking-joker.png";
import joker from "assets/game/joker.svg";
import fiftyFifty from "assets/game/fifty-fifty.svg";
import openTreasure from "assets/game/open-treasure.svg";
// Language related imports
import finalChoice from "assets/game/en/final-choice-screen.png";
import ranking from "assets/game/en/ranking-screen.png";

export default {
  rulesTitle: ["Goal", "Joker", "50:50", "Qualification round", "Final phase", "Super-bonus", "General ranking"],
  rulesImagesArray: [papagayo, joker, fiftyFifty, papagayoJoker, finalChoice, openTreasure, ranking],
  rulesRichText: [
    "<p>Get as many correct answers as possible <strong>without any wrong answer</strong> to get through the qualification rounds, win the final and earn as many points as possible. The players accumulate points over the rounds in order to progress in the general ranking and enter the TOP 10.</p>",
    "<p>When the game starts, the player has one JOKER he can use whenever he hesitates. Thanks to this JOKER, the player moves on to the next question but does not score any point on this question.</p>",
    "<p>If the player hesitates, the player can use the 50:50. The player can now choose among 2 answers instead of 4. But, the player scores only <strong>half of the points to this question</strong>.</p>",
    "<p>Each game starts with two rounds in a row of 4 questions.<br />If the player gives a bad answer during the round, the player loses and the game is over.<br />When the player passes a round, he wins a new joker.</p>",
    "<p>In the final phase, after each good answer given, the player can choose to move on the next question or to leave the game.<br />If he stops, he keeps his points. If he decides to move on, he takes the risk to lose all the points if he does not answer correctly to the next question.</p>",
    "<p>In case of final victory, the player wins the SUPER-BONUS, which boosts his score and allows him to make a significant leap in the general ranking.</p>",
    "<p>The players accumulate more or less points after each game. A general ranking of the scores enables to identify the Top best players.</p>",
  ],
};
