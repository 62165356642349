import React, { useContext } from "react";
import { observer } from "mobx-react";
import CreateIcon from "@material-ui/icons/Create";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

import { CharacterEditorViewer } from "../../Ducks/Avatar/components/CharacterViewer/CharacterViewer.component";
import LogicContext from "../../context/context";
import { LANDING } from "../../routing";
import PlayerData, { PlayerDataProps } from "../PlayerData/PlayerData.component";
import { PlayerDataContainer, StyloForUpdateContainer, ViewerContainer } from "./PlayerHud.style";
import { capitalize } from "../../utils";

import rankBadge from "assets/player/rank-badge.svg";
import scoreBadge from "assets/player/score-badge.svg";

interface Props {
  updateAvatar?: () => void;
}

const PlayerHud: React.FC<Props> = props => {
  const { userState, applicationState } = useContext(LogicContext);
  const { t } = useTranslation("common");

  if (!userState.store.me?.avatar || !applicationState.store.avatarPartsList) return <Redirect to={LANDING.path} />;

  const updateAvatar = () => {
    props.updateAvatar && props.updateAvatar();
  };

  const playerData: PlayerDataProps[] = [
    {
      className: "rank",
      illustrationUrl: rankBadge,
      title: capitalize(t("rank")),
      description: `${userState.store.me.ranking.position + 1}/${userState.store.me.ranking.numberOfPlayers}`,
    },
    {
      className: "score",
      illustrationUrl: scoreBadge,
      title: capitalize(t("score")),
      description: `${userState.store.me.totalScore}`,
    },
  ];

  return (
    <div style={{ position: "relative" }} className={"player-hud"}>
      <ViewerContainer className="player-card-avatar">
        {props.updateAvatar && (
          <StyloForUpdateContainer onClick={updateAvatar}>
            <CreateIcon style={{ alignSelf: "center", width: "100%", height: "100%" }} />
          </StyloForUpdateContainer>
        )}
        {typeof userState.store.me.avatar === "string" ? (
          <CharacterEditorViewer type={"star"} avastarUrl={userState.store.me.avatar} />
        ) : (
          <CharacterEditorViewer
            type={"avatar"}
            urlParts={applicationState.store.avatarPartsList.url + "/avatar_parts/"}
            clothesIndex={userState.store.me.avatar.bodyIndex}
            earsIndex={userState.store.me.avatar.earsIndex}
            hairIndex={userState.store.me.avatar.hairIndex}
            eyesIndex={userState.store.me.avatar.eyesIndex}
            mouthIndex={userState.store.me.avatar.mouthIndex}
            noseIndex={userState.store.me.avatar.noseIndex}
            pencilIndex={userState.store.me.avatar.pencilIndex}
            shapeIndex={userState.store.me.avatar.shapeIndex}
          />
        )}
      </ViewerContainer>
      <PlayerDataContainer className={"player-card-data "}>
        {playerData.map(data => (
          <PlayerData key={data.title} {...data} />
        ))}
      </PlayerDataContainer>
    </div>
  );
};

export default observer(PlayerHud);
