import chrono from "assets/sound/chrono.mp3";
import newJoker from "assets/sound/earnJoker.mp3";
import goodAnswer from "assets/sound/goodAnswer.mp3";
import openLock from "assets/sound/openLock.mp3";
import papagayo from "assets/sound/papagayoApparition.mp3";
import roundIntro from "assets/sound/roundIntro.mp3";
import success from "assets/sound/roundIntro.mp3";
import superBonusWin from "assets/sound/success.mp3";
import wrongAnswer from "assets/sound/wrongAnswer.mp3";

const soundList = {
  newJoker,
  goodAnswer,
  openLock,
  papagayo,
  roundIntro,
  success,
  superBonusWin,
  wrongAnswer,
};

const loopSoundList = {
  chrono,
};

const audio = new Audio();

export const playSound = (key: keyof typeof soundList) => {
  audio.src = soundList[key];
  audio.loop = false;
  audio.play();
};

export const playLoopSound = (key: keyof typeof loopSoundList) => {
  audio.src = loopSoundList[key];
  audio.loop = true;
  audio.play();
};

export const pauseSound = () => {
  audio.pause();
};

export const muteAllSound = (mute: boolean) => {
  const arrayOfSound = Object.keys(soundList);

  for (let i = 0; i < arrayOfSound.length; i++) {
    if (mute === true) {
      audio.muted = true;
    } else {
      audio.muted = false;
    }
  }
};
