import papagayo from "assets/papagayo/papagayo-high-res.png";
import papagayoJoker from "assets/papagayo/papagayo-speaking-joker.png";
import joker from "assets/game/joker.svg";
import fiftyFifty from "assets/game/fifty-fifty.svg";
import openTreasure from "assets/game/open-treasure.svg";
// Language related imports
import finalChoice from "assets/game/fr/final-choice-screen.png";
import ranking from "assets/game/fr/ranking-screen.png";

export default {
  rulesTitle: [
    "But du jeu",
    "Joker",
    "Le 50:50",
    "Round de qualification",
    "Phase finale",
    "Super-bonus",
    "Classement général",
  ],
  rulesImagesArray: [papagayo, joker, fiftyFifty, papagayoJoker, finalChoice, openTreasure, ranking],
  rulesRichText: [
    "<p>Enchainez autant de questions que possible <strong>sans se tromper</strong> pour passer les rounds de qualification, gagner la finale et cr&eacute;diter le maximum de points. Les joueurs cumulent des points au fil des parties pour progresser au classement g&eacute;n&eacute;ral et rentrer dans le TOP 10.</p>",
    "<p>Au lancement de la partie, le joueur dispose d&rsquo;un JOKER qu&rsquo;il peut utiliser lorsqu&rsquo;il h&eacute;site sur une question. Gr&acirc;ce &agrave; son JOKER, le joueur passe &agrave; la question suivante mais ne marque pas de points sur la question.</p>",
    "<p>En cas d&rsquo;h&eacute;sitation, le joueur peut aussi utiliser le &laquo;50:50&raquo;. La r&eacute;ponse &agrave; la question est alors facilit&eacute;e car le joueur n&rsquo;a plus le choix qu&rsquo;entre 2 propositions. En revanche, le joueur ne cr&eacute;dite <strong>que la moiti&eacute; des points de la question</strong>.</p>",
    "<p>Chaque partie d&eacute;bute par deux rounds successifs de 4 questions.&nbsp;<br />En cas de mauvaise r&eacute;ponse au cours d&rsquo;un round, le joueur est &eacute;limin&eacute; et la partie est termin&eacute;e.<br />Lorsqu&rsquo;un joueur passe un round, il gagne un nouveau JOKER.</p>",
    "<p>En phase finale, le joueur a le choix &agrave; l&rsquo;issue de chaque question de poursuivre la partie ou d&rsquo;en rester l&agrave;.<br />S&rsquo;il s&rsquo;arr&ecirc;te, il encaisse alors ses points. S&rsquo;il poursuit, il prend le risque tout perdre en cas de mauvaise r&eacute;ponse &agrave; la question suivante.</p>",
    "<p>En cas de victoire en finale, le joueur remporte le SUPER-BONUS qui lui permet de booster son score et de faire un bond important au classement g&eacute;n&eacute;ral du jeu.</p>",
    "<p>Les joueurs cumulent plus ou moins de points &agrave; chaque partie. Un classement g&eacute;n&eacute;ral des scores cumul&eacute;s permet de d&eacute;terminer le TOP des meilleurs joueurs.</p>",
  ],
};
