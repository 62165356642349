import styled from "styled-components";
import greenCheck from "assets/green-check.png";

export const CheckIconDiv = styled.div`
  background: url(${greenCheck});
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 100%;
  width: 100%;
`;
