import React from "react";
import { NavigationFooterButton, LogoUpAndGo, ModalContent } from "../../DumbComponents";
import { Col } from "./Footer.style";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TranslatedContent } from "../../Ducks/application/models/application.model";
import { translateContent } from "../../i18n";
import StandardModal from "../Modal/StandardModal.component";
import upandgoLogo from "assets/logo-upandgo-white.png";
import { toJS } from "mobx";

interface Props {
  secondaryColor: string;
  footerButtonList: FooterItem[];
}

interface FooterItem {
  htmlSelector: string;
  buttonName: TranslatedContent[];
  value: TranslatedContent[];
  type: "link" | "modal";
}

interface FooterLinkButton {
  id: string;
  buttonName: TranslatedContent[];
  linkValue: TranslatedContent[];
  type: "link";
}

interface FooterModalButton {
  id: string;
  buttonName: TranslatedContent[];
  modalContent: TranslatedContent[];
  type: "modal";
}

type NewFooterButton = FooterLinkButton | FooterModalButton;

const Footer: React.FC<Props> = props => {
  const { t } = useTranslation("answeredQuestions");

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const footerItemList = toJS(props.footerButtonList);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const footerColsNumber = footerItemList.length + 1;
  const footerColsWidthInPercents = 100 / footerColsNumber;

  return (
    <React.Fragment>
      <Col widthInPercents={footerColsWidthInPercents}>
        <LogoUpAndGo imageUrl={upandgoLogo} />
      </Col>
      {footerItemList.map((content, index) => (
        <React.Fragment key={index}>
          <Col widthInPercents={footerColsWidthInPercents}>
            <NavigationFooterButton
              id={content.htmlSelector}
              buttonType={content.type}
              openModal={openModal}
              key={index}
              buttonText={translateContent(content.buttonName)}
              link={content.type === "link" ? translateContent(content.value) : ""}
            />
          </Col>
          {content.type === "modal" && (
            <StandardModal
              customStyle={{ width: "1600px" }}
              isModalOpen={modalIsOpen}
              customClass={`${content.htmlSelector}_modal-container`}
              onClickClose={closeModal}
            >
              <ModalContent
                modalType={"freeHTML"}
                htmlText={translateContent(content.value)}
                secondaryColor={props.secondaryColor}
              />
            </StandardModal>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default observer(Footer);
