import styled from "styled-components";

export const GlobalContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 100vw;
  overflow: auto;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #52acff, #90dff5);
`;
