import styled from "styled-components";
import { theme } from "../../../theme";

export const TextButton = styled.button`
  background-image: linear-gradient(to bottom, #8dc63f, #fdee22);
  font-family: ${({ theme }) => theme.font.mainFont};
  border-radius: 20rem;
  box-shadow: inset 0px -5px 0 0px #94b500, rgb(255, 255, 255) 0px 2px 1px 0px inset;
  color: #fff;
  font-size: 2em;
  font-weight: bolder;
  padding: 0.5em 1.5em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  z-index: 1200;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1.3em;
    padding: 0.5em 1em;
    box-shadow: inset 0px -3px 0 0px #94b500, rgb(255, 255, 255) 0px 2px 1px 0px inset;
  }
`;

export const TextButtonContainer = styled.div`
  border-radius: 20rem;
  background-image: linear-gradient(rgb(141, 198, 63), rgb(253, 238, 34));
  border: 4px solid rgb(255, 241, 216);
  padding-top: 3px;
  display: flex;
  justify-content: center;
  align-content: center;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    border: 3px solid rgb(255, 241, 216);
  }
`;
