import AuthStore from "./auth.store";
import { action } from "mobx";
import { AuthConfig, AuthService } from "@upandgo/auth-package";

export interface AuthMutatorInterface {
  setAuthService: (config: AuthConfig) => void;
}

export default class Mutator implements AuthMutatorInterface {
  private readonly authStore: AuthStore;

  constructor(props: { authStore: AuthStore }) {
    this.authStore = props.authStore;
  }

  @action
  setAuthService(config: AuthConfig) {
    this.authStore.authService = new AuthService({ ...config, debugMode: true });
  }
}
