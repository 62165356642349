import baseStyled, { createGlobalStyle, ThemedStyledInterface } from "styled-components";
import nunitoRegular from "assets/fonts/Nunito-Regular.ttf";
import nunitoBold from "assets/fonts/Nunito-Bold.ttf";
import nunitoExtraBold from "assets/fonts/Nunito-ExtraBold.ttf";
import nunitoLight from "assets/fonts/Nunito-Light.ttf";
import lilitaOneRegular from "assets/fonts/LilitaOne-Regular.ttf";
import amikoRegular from "assets/fonts/Amiko-Regular.ttf";

export const theme = {
  font: {
    mainFont: "Nunito",
    styledFont: "Lilita",
  },
  color: {
    primary: "#b51f04",
    secondary: "#e07b39",
    header: "#ffffff",
    text: "#ffffff",
    textSecondary: "#000000",
    textStyled: "#add136",
  },
  mobileFirst: {
    breakPoint: "768px",
  },
  notebook: {
    breakPoint: "1600px",
  },
};

export type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme>;

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
@font-face {
  font-family: ${({ theme }) => theme.font.mainFont};
  src: local("Nunito") url(${nunitoRegular}) format("truetype");
}

@font-face {
  font-family: ${({ theme }) => theme.font.mainFont};
  font-weight: bold;
  src: local("Nunito"), url(${nunitoBold}) format("truetype");
}

@font-face {
  font-family: ${({ theme }) => theme.font.mainFont};
  font-weight: bolder;
  src: local("Nunito"), url(${nunitoExtraBold}) format("truetype");
}

@font-face {
  font-family: ${({ theme }) => theme.font.mainFont};
  font-weight: lighter;
  src: local("Nunito"), url(${nunitoLight}) format("truetype");
}

@font-face {
  font-family: ${({ theme }) => theme.font.styledFont};
  src: url(${lilitaOneRegular}) format("truetype");
}

@font-face {
  font-family: "Amiko";
  src: url(${amikoRegular}) format("truetype");
}

* {
  margin: 0;
  font-family: ${({ theme }) => theme.font.mainFont}, Fallback, sans-serif;
}

html {
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rounded {
  border-radius: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.underline {
  // This approach is used to put the line away from text - ABEDOS 04/08/21
  // https://www.w3docs.com/snippets/css/how-to-increase-the-space-between-text-and-underlining-in-css.html
  padding-bottom: 0.1em;
  text-decoration: underline;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.link {
  font-weight: bolder;
  color: ${({ theme }) => theme.color.text};
  font-size: 1.56em;
  cursor: pointer;
}
`;
