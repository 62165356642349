import React from "react";
import { FooterButtonDiv, FooterUrlLinkDiv } from "./NavigationFooterButton.style";
interface Props {
  buttonText: string;
  openModal?: () => void;
  buttonType: "modal" | "link";
  link?: string;
  id: string;
}

const NavigationButtonLink: React.FC<Props> = props => {
  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    props.openModal && props.openModal();
  };
  if (props.buttonType === "modal")
    return (
      <FooterButtonDiv className={`${props.id}_modal-button`} onClick={openModal}>
        {props.buttonText}{" "}
      </FooterButtonDiv>
    );
  if (props.buttonType === "link") {
    return (
      <FooterUrlLinkDiv className={`${props.id}_link`} href={props.link} target="_blank">
        {props.buttonText}
      </FooterUrlLinkDiv>
    );
  }
  return <div></div>;
};

export default NavigationButtonLink;
