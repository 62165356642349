import React from "react";
import { styled, theme } from "../../theme";

const P = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.color.text};
  font-size: 1.7em;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1em;
  }

  &.light {
    font-weight: lighter;
  }

  &.bolder {
    font-weight: bolder;
  }

  &.normal {
    font-weight: normal;
  }

  &.secondary {
    color: ${({ theme }) => theme.color.textSecondary};
  }

  &.styled {
    color: ${({ theme }) => theme.color.textStyled};
  }

  &.small {
    font-size: 1em;
  }
  &.medium {
    font-size: 1.3em;
  }

  &.xl {
    font-size: 1.8em;
  }

  &.center {
    text-align: center;
  }

  &.advice-title {
    font-size: 25px;
  }

  &.hud-label {
    font-size: 27px;

    @media (max-width: ${theme.mobileFirst.breakPoint}) {
      font-size: 17px;
    }
  }

  &.hud-value {
    font-size: 38px;

    @media (max-width: ${theme.mobileFirst.breakPoint}) {
      font-size: 26px;
    }
  }
`;

interface Props {
  text: string;
}

const StandardText: React.FC<Props & React.HTMLAttributes<HTMLParagraphElement>> = props => {
  const { className, text, ...rest } = props;

  return (
    <P className={`standard-text ${className ? className : ""}`} {...rest}>
      {text}
    </P>
  );
};

export default StandardText;
