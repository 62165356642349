import React from "react";
import { ModalHTMLTextContainer, TextBlock } from "./ModalHTMLText.style";

interface Props {
  htmlText: string;
  secondaryColor: string;
}

const ModalHTMLText: React.FC<Props> = props => {
  const createMarkup = () => {
    return { __html: props.htmlText };
  };
  return (
    <>
      <ModalHTMLTextContainer>
        <TextBlock dangerouslySetInnerHTML={createMarkup()} />
      </ModalHTMLTextContainer>
    </>
  );
};

export default ModalHTMLText;
