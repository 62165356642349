import styled from "styled-components";
import redCross from "assets/red-cross.png";

export const RedMarkIconDiv = styled.div`
  background: url(${redCross});
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 100%;
  width: 100%;
`;
