import Core from "./User.core";
import UserState from "../state";
import userService from "../services/User.service";
import storageService from "../../../services/storage.service";
import applicationState from "../../application/state";
import AuthState from "../../Auth/state";

const userCore = Core({
  userState: UserState,
  userService,
  storageService,
  applicationState,
  authState: AuthState,
});

export default userCore;
