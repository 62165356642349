import Core from "./auth.core";
import authState from "../state";
import storageService from "../../../services/storage.service";

const authCore = Core({
  storageService,
  authState,
});

export default authCore;
