import styled, { keyframes, css } from "styled-components";
import { theme } from "../../theme";

import papagayo from "assets/papagayo/papagayo.png";
import nose from "assets/papagayo/bec.png";

export const PapagayoBirdStyle = styled.div`
  background: url(${papagayo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 40%;
  position: relative;
  bottom: 0;
  transform-origin: center;
  transform: rotateY(180deg);
  height: 100%;
  width: 100%;
  z-index: 5000;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    right: 5%;
    min-height: 30%;
  }
`;

export const PapagayoAnimation = keyframes`
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(-10%);
  }
`;

interface PapagayoAnimation {
  stopAnimation?: boolean | undefined;
}

export const PapagayoBec = styled.div<PapagayoAnimation>`
  background: url(${nose});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center;
  height: 30%;
  width: 30%;
  right: 64%;
  top: 50%;
  ${props =>
    !props.stopAnimation &&
    css`
      animation: ${PapagayoAnimation} 0.2s;
      animation-iteration-count: infinite;
    `}
  position: absolute;

  z-index: 5000;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    height: 25%;
    width: 25%;
    right: 65%;
    top: 50%;
    animation-name: ${PapagayoAnimation} 1s;
    animation-fill-mode: infinite;
  }
`;

export const PapagayoCircleFrame = styled.div`
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  height: 10em;
  width: 10em;
`;
